import { Form, Input, InputNumber, Modal, Select, Tag } from "antd";
import { FC, useState } from "react";
import { useAppSelector } from "src/app/hooks";
import { selectUser } from "src/features/authentication/userSlice";
import { orgSelect } from "src/features/orgs/orgSlice";
import { axiosInstanceV2 } from "src/helpers";
import { formSubmit, validateNumericValue } from "src/shared/config/constants";

interface Props {
  open: boolean;
  onClose: () => void;
  user: AppUser & AppUserPublic;
}

interface FormType {
  gemBalance: AppUser["gemBalance"] | null | string;
  description: string | null;
  note: string | null;
  gameId: string;
}

const AddGemstoUser: FC<Props> = ({ onClose: onModalClose, open, user }) => {
  const [form] = Form.useForm<FormType>();

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { user: loggedInUser } = useAppSelector(selectUser);
  const { orgs } = useAppSelector(orgSelect);
  const { listOfAllGames } = useAppSelector((state) => state.gameState);

  const handleSubmit = formSubmit(form);

  async function onFinish({ gemBalance, description, gameId, note }: FormType) {
    setError("");
    if (
      !user ||
      !loggedInUser ||
      !loggedInUser.activeOrgId ||
      !orgs[loggedInUser.activeOrgId] ||
      !orgs[loggedInUser.activeOrgId].activeContractId
    ) {
      setError("Refresh or retry, user information is missing");

      return;
    }

    setIsLoading(true);
    try {
      await axiosInstanceV2.post("internal/users/update_balance", {
        appUserUid: user.uid,
        gemAmount: gemBalance,
        description: description || "",
        bonusCashAmount: 0,
        note: note || undefined,
        amount: 0,
        gameId,
      });
      onClose();
    } catch (error) {
      const _e = error as Error;
      setError(_e.message || "Unknown Error");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  function onClose() {
    form.resetFields();
    setError("");
    setIsLoading(false);
    onModalClose();
  }

  return (
    <>
      <Modal
        title="Add to User Gems Balance"
        centered
        open={open}
        okText="Add"
        confirmLoading={isLoading}
        onOk={() => handleSubmit(onFinish)}
        onCancel={onClose}
      >
        <Form
          name="Gems Balance"
          form={form}
          className="app-config-form"
          initialValues={{ gemBalance: 0 }}
          autoComplete="off"
        >
          <Form.Item
            label="Gems Balance"
            name="gemBalance"
            className="app-config-input-wrap"
            rules={[
              {
                required: true,
                message: "Gems Balance value is required",
                validator: validateNumericValue,
              },
            ]}
          >
            <InputNumber
              name="gemBalance"
              size="middle"
              type="number"
              onChange={(value) => {
                form.setFieldsValue({
                  ...form.getFieldsValue(),
                  gemBalance: value,
                });
              }}
            />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            className="app-config-input-wrap bordered-select"
            rules={[
              {
                required: true,
                message: "Description value is required",
              },
            ]}
          >
            <Select
              size="large"
              onChange={(value) => {
                form.setFieldsValue({
                  ...form.getFieldsValue(),
                  description: value,
                });
              }}
            >
              <Select.Option value="SDK-Async-1v1-crash">
                SDK-Async-1v1-crash
              </Select.Option>
              <Select.Option value="SDK-Async-group-crash">
                SDK-Async-group-crash
              </Select.Option>
              <Select.Option value="SDK-Blitz-crash">
                SDK-Blitz-crash
              </Select.Option>
              <Select.Option value="Game-crash">Game-crash</Select.Option>
              <Select.Option value="Marketing-general">
                Marketing-general
              </Select.Option>
              <Select.Option value="Marketing-creator">
                Marketing-creator
              </Select.Option>
              <Select.Option value="Marketing-research">
                Marketing-research
              </Select.Option>
              <Select.Option value="Marketing-other">
                Marketing-other
              </Select.Option>
              <Select.Option value="Testing">Testing</Select.Option>
              <Select.Option value="Other"> Other </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Game"
            name="gameId"
            className="app-config-input-wrap bordered-select"
            rules={[
              {
                required: true,
                message: "Game is required",
              },
            ]}
          >
            <Select
              size="large"
              onChange={(value) => {
                form.setFieldsValue({
                  ...form.getFieldsValue(),
                  gameId: value,
                });
              }}
            >
              {listOfAllGames
                .filter((f) => f.goLiveStatus === "live")
                .map((e) => (
                  <Select.Option key={e.id} value={e.id}>
                    {e.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Note (Optional)"
            name="note"
            className="app-config-input-wrap"
            rules={[
              {
                required: false,
                message: "Provide a proper note",
              },
            ]}
          >
            <Input.TextArea
              placeholder="write a note for this transaction"
              allowClear
              onChange={(event) => {
                form.setFieldsValue({
                  ...form.getFieldsValue(),
                  note: event.target.value,
                });
              }}
            />
          </Form.Item>
          {error && <Tag color="red">{error}</Tag>}
        </Form>
      </Modal>
    </>
  );
};

export default AddGemstoUser;
