import { Table, TableColumnsType, TablePaginationConfig } from "antd";
import { FC } from "react";
import GameNameV2 from "src/shared/components/GameName/gameNameV2";

type Props = {
  stats: UserStatisticsAmountsResponse["paidGamesStats"];
};
const FraudTable: FC<Props> = ({ stats }) => {
  const columns: TableColumnsType<{
    blitz_games_played: number;
    game_id: string;
    group_tournament_games_played: number;
    total_games_played: number;
  }> = [
    {
      title: "Games",
      dataIndex: "game_id",
      key: "game_id",
      render: (value) => <GameNameV2 id={value} />,
    },
    {
      title: "Blitz Games",
      dataIndex: "blitz_games_played",
      key: "blitz_games_played",
    },
    {
      title: "Group Tournament Games",
      dataIndex: "group_tournament_games_played",
      key: "group_tournament_games_played",
    },
    {
      title: "Total Game Played",
      dataIndex: "total_games_played",
      key: "total_games_played",
    },
  ];
  const paginationConfig: TablePaginationConfig = {
    defaultPageSize: 50,
    className: "normal-option-size",
  };

  return (
    <Table
      columns={columns}
      loading={!stats}
      rowKey="game_id"
      dataSource={stats}
      pagination={paginationConfig}
      className="mobile-table-small bottom"
    />
  );
};

export default FraudTable;
