import { Collapse, Radio, RadioChangeEvent, Row, Typography } from "antd";
import { FC, useMemo, useState } from "react";
import FraudGraph from "./fraudGraph";
import FraudTable from "./fraudTable";

type Props = {
  data: UserStatisticsAmountsResponse["paidGamesStats"];
};

const GraphTypeOptions = ["Table", "Graph"];

const FRAUD_GRAPH_SETTING_KEY = "fraud-graph-type";
export function saveGraphSettings(value: string) {
  localStorage.setItem(FRAUD_GRAPH_SETTING_KEY, value);
  return localStorage.getItem(FRAUD_GRAPH_SETTING_KEY);
}

export function getGraphSettings<T>() {
  return localStorage.getItem(FRAUD_GRAPH_SETTING_KEY) as T;
}

const FraudStats: FC<Props> = ({ data }) => {
  const [graphType, setGraphType] = useState<"Table" | "Graph">(
    () => getGraphSettings() || "Graph"
  );

  const onToggleGraph = (e: RadioChangeEvent) => {
    setGraphType(e.target.value);
    saveGraphSettings(e.target.value);
  };

  const graphData = useMemo(() => {
    return data.reduce(
      (prev, curr) => {
        return {
          totalAsync: prev.totalAsync + curr.group_tournament_games_played,
          totalBlitz: prev.totalBlitz + curr.blitz_games_played,
          totalGames: prev.totalGames + curr.total_games_played,
        };
      },

      {
        totalAsync: 0,
        totalBlitz: 0,
        totalGames: 0,
      }
    );
  }, [data]);

  return (
    <Collapse ghost>
      <Collapse.Panel
        header={<Typography.Title level={5}>Fraud Statistics</Typography.Title>}
        key="1"
      >
        <Row justify="end" className="mb-2">
          <Radio.Group
            options={GraphTypeOptions}
            onChange={onToggleGraph}
            value={graphType}
            optionType="button"
            buttonStyle="solid"
          />
        </Row>
        {graphType === "Table" && <FraudTable stats={data} />}
        {graphType === "Graph" && <FraudGraph {...graphData} data={data} />}
      </Collapse.Panel>
    </Collapse>
  );
};

export default FraudStats;
